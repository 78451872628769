<template>
  <div class="customer-page">
    <Nav />
    <div class="customer-top">
      <h1 class="title">{{ title }}</h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="white"
          fill-opacity="0.7"
        />
      </svg>
    </div>
    <!-- <div class="customer-wrap" :class="{ 'has-online': online }">
      <div class="tip">
        <svg
          style="margin-right: 8px"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.3035 12.6667L1.3335 15V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2H14.0002C14.177 2 14.3465 2.07024 14.4716 2.19526C14.5966 2.32029 14.6668 2.48986 14.6668 2.66667V12C14.6668 12.1768 14.5966 12.3464 14.4716 12.4714C14.3465 12.5964 14.177 12.6667 14.0002 12.6667H4.3035ZM4.66683 6.66667C4.66683 7.55072 5.01802 8.39857 5.64314 9.02369C6.26826 9.64881 7.11611 10 8.00016 10C8.88422 10 9.73206 9.64881 10.3572 9.02369C10.9823 8.39857 11.3335 7.55072 11.3335 6.66667H10.0002C10.0002 7.1971 9.78945 7.70581 9.41438 8.08088C9.0393 8.45595 8.5306 8.66667 8.00016 8.66667C7.46973 8.66667 6.96102 8.45595 6.58595 8.08088C6.21088 7.70581 6.00016 7.1971 6.00016 6.66667H4.66683Z"
            fill="#212027"
          />
        </svg>
        {{ tipText }}
      </div>
      <div v-if="online" class="online-main">
        <div class="border-box">
          <div class="border"></div>
        </div>
        <div class="custom-form">
          <van-form @submit="onSubmit">
            <van-field name="gender" class="radio-cell">
              <template #input>
                <van-radio-group v-model="form.gender" direction="horizontal">
                  <van-radio name="man">先生</van-radio>
                  <van-radio name="woman">女士</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="form.company"
              name="company"
              placeholder="公司名称*"
              :rules="[{ required: true, message: '请输入公司名称*' }]"
            />
            <van-field
              v-model="form.username"
              name="username"
              placeholder="姓名*"
              :rules="[{ required: true, message: '请输入公司名称*' }]"
            />
            <van-field
              v-model="form.email"
              name="email"
              placeholder="电子邮箱*"
              :rules="[
                { required: true, message: '请输入公司名称*' },
                {
                  pattern:
                    /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                  message: '邮箱格式错误！',
                },
              ]"
            />
            <van-field name="agree" class="radio-cell radio-cell-long">
              <template #input>
                <van-radio-group v-model="form.agree" direction="horizontal">
                  <van-radio :name="true"
                    >请接受
                    <span class="bold">《数据保护协议》</span>
                    以启动 eChat。</van-radio
                  >
                </van-radio-group>
              </template>
            </van-field>

            <div class="btn-wrap">
              <van-button round block type="info" native-type="submit"
                >eChat开始</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
      <div class="offline-main" v-else>
        <div class="border-box">
          <div class="border"></div>
        </div>
        <p class="text">
          目前，没有服务顾问在线。请您在稍后时间查询。您也可以通过电话
          400-176-1166 与我们联系或写电子邮件至 <span>cn@HARTING.com.</span>
        </p>
        <p class="text">
          谢谢!
          <br />您的客户服务专员
        </p>
      </div>
    </div> -->
    <div class="iframe-box">
      <div v-if="isLoading" class="laoding-box" >
        <van-loading color="#fc0">加载中...</van-loading>
      </div>
      <iframe :src="formUrl" @load="onLoad" width="100%" height="100%"></iframe> 
    </div>
    
  </div>
</template>
<script>
import { Form, Field, RadioGroup, Radio, Button, Loading } from "vant";
import Nav from "../../component/nav.vue";
export default {
  name: "CUSTOMER",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Loading.name]: Loading,
    Nav
  },
  data() {
    return {
      online: true,
      username: "",
      form: {
        gender: "man",
        company: "",
        username: "",
        email: "",
        agree: false,
      },
      isLoading: true,
      formUrl: 'https://b2b.harting.com.cn/b2bchat/next_cockpit_harting/popup.html?lang=CN-ZH-HANS&cat=CN_Chat'
      // title: '在线客服 eChat'
    };
  },
  computed: {
    title() {
      return this.online === true ? "在线客服 eChat" : "通告";
    },
    tipText() {
      return this.online === true
        ? "客户服务专员现正在线。"
        : "目前，没有服务顾问在线。";
    },
  },
  methods: {
    onSubmit(form) {
      if (!form.agree) {
        this.$notify({
          message: "请接受《数据保护协议》",
          color: "#000",
          background: "#fc0",
        });
      }
    },
    onLoad() {
      this.isLoading = false;  
    }
  },
};
</script>
<style lang="less" scoped>
.customer-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: @common-bg;
  .customer-top {
    padding: 92px 32px 32px 32px;
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
  }
  .customer-wrap {
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 210px);
    padding: 40px 32px;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
    .tip {
      display: flex;
      align-items: center;
      color: #212027;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
    .border-box {
      background: none;
      padding: 24px 0;
      font-size: 0;
      .border {
        width: 100%;
        height: 2px;
        background: #fff;
        opacity: 0.5;
      }
    }
    .offline-main {
      .text {
        margin-bottom: 24px;
        color: #505054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        span {
          color: @theme-color;
          font-weight: 600;
          line-height: 160%;
          text-decoration-line: underline;
        }
      }
    }
  }
  .customer-wrap.has-online {
    background: @theme-color;
    .border-box {
      padding: 16px 0;
    }
  }
  .iframe-box{
    position: relative;
    width: 100%;
    height: calc(100vh - 150px - 118px);
    .laoding-box{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%; -50%);
    }
  }
}
</style>
<style lang="less">

</style>
